import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { Bordereau } from '../../../../api/commissionnement/model/bordereau';
import { Constants } from '../../../../shared/constants';
import { PartenaireService } from '../../../../shared/services/facade/partenaire-facade.service';
import { telechargerBordereau } from '../../../../shared/utils';

@Component({
  selector: 'espace-partenaire-tableau-bordereau',
  standalone: true,
  imports: [MatTableModule, MatPaginator, DatePipe, RouterLink, CurrencyPipe],
  templateUrl: './tableau-bordereau.component.html',
  styles: [],
})
export class TableauBordereauComponent {
  public readonly format = Constants.FORMAT_DATE_FR;
  public readonly displayedColumns: string[] = [
    'informations',
    'dateCreation',
    'datePaiement',
    'montant',
    'statut',
    'action',
  ];

  private readonly partenaireService = inject(PartenaireService);

  @Input({ required: true })
  public bordereaux: Bordereau[] = [];

  public appelTelechargerBordereau(idBordereau: string): void {
    telechargerBordereau(this.partenaireService, idBordereau);
  }
}
