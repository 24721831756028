import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BASE_URL, BASE_URL_PARTENAIRE } from '../../../config/api.config';
import { FiltreBordereaux } from '../../../shared/interfaces/filtre-bordereaux.interface';
import { FiltreCommissions } from '../../../shared/interfaces/filtre-commissions.interface';
import { PartenaireService } from '../../../shared/services/facade/partenaire-facade.service';
import { formatDateISO8601 } from '../../../shared/utils';
import { Bordereau } from '../model/bordereau';
import { BordereauDetaillePartenaire } from '../model/bordereau-detaille-partenaire';
import { ConsulterBordereauPartenaireResponse } from '../model/consulter-bordereau-partenaire-response';
import { ConsulterTypesCommissionsResponse } from '../model/consulter-types-commissions-response';
import { RechercherBordereauxPartenaireResponse } from '../model/rechercher-bordereaux-partenaire-response';
import { RechercherCommissionsBordereauPartenaireResponse } from '../model/rechercher-commissions-bordereau-partenaire-response';
import { TelechargerBordereauCaracResponse } from '../model/telecharger-bordereau-carac-response';
import { TypesCommission } from '../model/types-commission';
import { Utilisateur } from '../model/utilisateur';

@Injectable()
export class PartenaireApiService extends PartenaireService {
  private readonly httpClient = inject(HttpClient);
  private readonly basePath: string = inject(BASE_URL);
  private readonly basePathPartenaire: string = inject(BASE_URL_PARTENAIRE);

  public override getBordereau(idBordereau: string): Observable<BordereauDetaillePartenaire> {
    return this.httpClient
      .get<ConsulterBordereauPartenaireResponse>(`${this.basePath}${this.basePathPartenaire}/bordereaux/${idBordereau}`)
      .pipe(map((result) => result.bordereau!));
  }

  public override getBordereaux(filtres: FiltreBordereaux): Observable<Bordereau[]> {
    let params = new HttpParams();
    if (filtres.datePaiementStart) {
      params = params.append('datePaiementMin', formatDateISO8601(filtres.datePaiementStart));
    }
    if (filtres.datePaiementEnd) {
      params = params.append('datePaiementMax', formatDateISO8601(filtres.datePaiementEnd));
    }
    if (filtres.dateCreationStart) {
      params = params.append('dateCreationMin', formatDateISO8601(filtres.dateCreationStart));
    }
    if (filtres.dateCreationEnd) {
      params = params.append('dateCreationMax', formatDateISO8601(filtres.dateCreationEnd));
    }
    if (filtres.apporteur) {
      params = params.append('idApporteur', filtres.apporteur);
    }
    if (filtres.idBordereauExterne) {
      params = params.append('idBordereauExterne', filtres.idBordereauExterne);
    }
    return this.httpClient
      .get<RechercherBordereauxPartenaireResponse>(`${this.basePath}${this.basePathPartenaire}/bordereaux`, {
        params,
      })
      .pipe(map((result: RechercherBordereauxPartenaireResponse) => result.bordereaux ?? []));
  }

  public override getCommissionByBordereau(
    idBordereau: string,
    page: number,
    pageSize: number,
    filtres: FiltreCommissions
  ): Observable<RechercherCommissionsBordereauPartenaireResponse> {
    let params = new HttpParams().appendAll({
      page,
      parPage: pageSize,
    });
    if (filtres.apporteur) {
      params = params.append('idApporteur', filtres.apporteur);
    }
    if (filtres.complet) {
      params = params.append('numeroComplet', filtres.complet);
    }
    if (filtres.typeCommission) {
      params = params.append('idRegleCommissionnement', filtres.typeCommission);
    }
    if (filtres.dateCommissionMin) {
      params = params.append('dateCreationCommissionMin', formatDateISO8601(filtres.dateCommissionMin));
    }
    if (filtres.dateCommissionMax) {
      params = params.append('dateCreationCommissionMax', formatDateISO8601(filtres.dateCommissionMax));
    }
    if (filtres.idCommissionExterne) {
      params = params.append('idCommissionExterne', filtres.idCommissionExterne);
    }
    return this.httpClient.get<RechercherCommissionsBordereauPartenaireResponse>(
      `${this.basePath}${this.basePathPartenaire}/bordereaux/${idBordereau}/commissions`,
      { params }
    );
  }

  public override consulterTypeCommissions(idBordereau: string): Observable<TypesCommission[]> {
    return this.httpClient
      .get<ConsulterTypesCommissionsResponse>(
        `${this.basePath}${this.basePathPartenaire}/bordereaux/${idBordereau}/consulter-types-commissions`
      )
      .pipe(map((response) => response.typesCommission ?? []));
  }

  public override telechargerBordereau(idBordereau: string): Observable<TelechargerBordereauCaracResponse> {
    return this.httpClient.get<TelechargerBordereauCaracResponse>(
      `${this.basePath}${this.basePathPartenaire}/bordereaux/${idBordereau}/telecharger-partenaire`
    );
  }

  public override getAgent(): Observable<Utilisateur> {
    return this.httpClient.get<Utilisateur>(`${this.basePath}${this.basePathPartenaire}/agents/me`);
  }
}
