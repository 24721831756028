import { DatePipe } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Bordereau } from '../../../../api/commissionnement/model/bordereau';
import { Constants, ETABLISSEMENTS, RESEAUX } from '../../../../shared/constants';
import { PartenaireService } from '../../../../shared/services/facade/partenaire-facade.service';
import { telechargerBordereau } from '../../../../shared/utils';

@Component({
  selector: 'espace-partenaire-informations-bordereau',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './informations-bordereau.component.html',
})
export class InformationsBordereauComponent {
  public readonly reseaux = RESEAUX;
  public readonly etablissements = ETABLISSEMENTS;
  public readonly formatDate = Constants.FORMAT_DATE_FR;
  private readonly partenaireService = inject(PartenaireService);

  @Input({ required: true })
  public bordereau!: Bordereau;

  public appelTelechargerBordereau(): void {
    telechargerBordereau(this.partenaireService, this.bordereau.idBordereau);
  }
}
