import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, mergeMap, of } from 'rxjs';
import { BordereauDetaillePartenaire } from '../../../../api/commissionnement/model/bordereau-detaille-partenaire';
import { RechercherCommissionsBordereauPartenaireResponse } from '../../../../api/commissionnement/model/rechercher-commissions-bordereau-partenaire-response';
import { TypesCommission } from '../../../../api/commissionnement/model/types-commission';
import { FiltreCommissions } from '../../../../shared/interfaces/filtre-commissions.interface';
import { PartenaireService } from '../../../../shared/services/facade/partenaire-facade.service';
import { InformationsBordereauComponent } from '../../components/informations-bordereau/informations-bordereau.component';
import { RechercheCommissionsComponent } from '../../components/recherche-commissions/recherche-commissions.component';
import { TableauCommissionComponent } from '../../components/tableau-commission/tableau-commission.component';
import { SyntheseCommissionsComponent } from '../../components/synthese-commissions/synthese-commissions.component';

@Component({
  selector: 'espace-partenaire-bordereau-detail',
  standalone: true,
  imports: [
    RouterLink,
    InformationsBordereauComponent,
    CommonModule,
    TableauCommissionComponent,
    RechercheCommissionsComponent,
    MatPaginatorModule,
    MatIconModule,
    SyntheseCommissionsComponent,
  ],
  templateUrl: './detail-bordereau.component.html',
})
export class BordereauDetailComponent implements OnInit {
  private readonly partenaireService = inject(PartenaireService);

  public readonly PAGE_SIZE = 10;

  public bordereau: BordereauDetaillePartenaire | undefined;
  public typesCommission: TypesCommission[] | undefined;

  public readonly commissions$: Observable<RechercherCommissionsBordereauPartenaireResponse | null>;
  private readonly page$ = new BehaviorSubject(0);
  private readonly filtres$ = new BehaviorSubject(null as FiltreCommissions | null);

  @Input()
  public idBordereau!: string;

  public constructor() {
    const commissionByBordereau$ = combineLatest([this.filtres$, this.page$]).pipe(
      mergeMap(([filtres, page]) => {
        if (filtres) {
          return this.partenaireService.getCommissionByBordereau(this.idBordereau, page, this.PAGE_SIZE, filtres);
        }
        return of(null);
      })
    );
    this.commissions$ = commissionByBordereau$;
  }

  public ngOnInit(): void {
    this.partenaireService.getBordereau(this.idBordereau).subscribe((bordereau) => (this.bordereau = bordereau));
    this.partenaireService
      .consulterTypeCommissions(this.idBordereau)
      .subscribe((typesCommisions) => (this.typesCommission = typesCommisions));
  }

  public setPage(page: number): void {
    this.page$.next(page);
  }

  public setFiltre(filtre: FiltreCommissions): void {
    this.filtres$.next(filtre);
  }
}
