import { EnvironmentProviders, InjectionToken, Provider } from '@angular/core';
import { environment } from '../../environments/environment';

export const BASE_URL = new InjectionToken<string>('');
export const BASE_URL_PARTENAIRE = new InjectionToken<string>('');

export const API_CONFIG: (EnvironmentProviders | Provider)[] = [
  {
    provide: BASE_URL,
    useValue: environment.api.url,
  },
  {
    provide: BASE_URL_PARTENAIRE,
    useValue: environment.api.partenaire,
  },
];
