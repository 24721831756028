<div class="container mx-auto py-3 flex flex-col">
  <h1 class="title my-4">CONSULTATION DES BORDEREAUX</h1>
  <div class="section-primary flex flex-col gap-4 mt-5">
    <espace-partenaire-recherche-bordereaux (rechercherBordereaux)="rechercherBordereaux($event)" />
  </div>
  <div class="section-primary flex flex-col gap-4 mt-7">
    <h2 class="title-2">Liste des bordereaux</h2>
    <div>
      @if (paginateBordereaux$ | async; as bordereaux) {
        <span [ngPlural]="bordereaux.length">
          <ng-template ngPluralCase="=0">Résultat : 0 bordereau à traiter</ng-template>
          <ng-template ngPluralCase="=1">Résultat : 1 bordereau à traiter</ng-template>
          <ng-template ngPluralCase="other">Résultat : {{ bordereaux.length }} bordereaux à traiter</ng-template>
        </span>
        @if (bordereaux.length > 0) {
          <espace-partenaire-tableau-bordereau [bordereaux]="bordereaux.bordereaux" />
          <mat-paginator
            class="paginator"
            (page)="setPage($event.pageIndex)"
            [hidePageSize]="true"
            [length]="bordereaux.length"
            [pageSize]="PAGE_SIZE"
            [showFirstLastButtons]="true"
          />
        }
      }
    </div>
  </div>
</div>
