import { formatDate } from '@angular/common';
import { TelechargerBordereauCaracResponse } from '../api/commissionnement/model/telecharger-bordereau-carac-response';
import { Constants } from './constants';
import { PartenaireService } from './services/facade/partenaire-facade.service';

export function formatDateFr(date: Date): string {
  return formatDate(date, Constants.FORMAT_DATE_FR, Constants.LOCALE);
}

export function formatDateISO8601(date: Date): string {
  return formatDate(date, Constants.FORMAT_DATE_ISO_8601, Constants.LOCALE);
}

export function telechargerBordereau(partenaireService: PartenaireService, bordereauId: string): void {
  partenaireService.telechargerBordereau(bordereauId).subscribe((response: TelechargerBordereauCaracResponse) => {
    const byteCharacters = atob(response.fichier!);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(byteNumbers)], { type: response.mimeType || 'application/zip' });
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = response.nomFichier || 'downloaded_file.zip';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  });
}
