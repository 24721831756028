import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, mergeMap, of, tap } from 'rxjs';
import { Utilisateur } from '../../api/commissionnement/model/utilisateur';
import { AuthentificationService } from './facade/authentification-facade.service';
import { PartenaireService } from './facade/partenaire-facade.service';

@Injectable({ providedIn: 'root' })
export class CurrentUtilisateurService {
  private readonly authentificationService = inject(AuthentificationService);
  private readonly partenaireService = inject(PartenaireService);

  private readonly _currentUtilisateur$ = new BehaviorSubject<Utilisateur | undefined>(undefined);
  public readonly currentUtilisateur$ = this._currentUtilisateur$.asObservable();
  public readonly utilisateur$ = this.authentificationService.isAuthenticated$.pipe(
    mergeMap((isAuthenticated) => {
      if (!isAuthenticated) {
        return of(false);
      }
      return this.partenaireService.getAgent().pipe(catchError(() => of(undefined)));
    }),
    tap((utilisateur) => {
      if (!(utilisateur instanceof Boolean)) {
        this._currentUtilisateur$.next(utilisateur as Utilisateur | undefined);
      }
    })
  );
}
