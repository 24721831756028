import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TypesCommission } from '../../../../api/commissionnement/model/types-commission';
import { TYPES_VALIDATION } from '../../../../shared/constants';
import { FiltreCommissions } from '../../../../shared/interfaces/filtre-commissions.interface';

@Component({
  selector: 'espace-partenaire-recherche-commissions',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, CommonModule, ReactiveFormsModule, MatIconModule],
  templateUrl: './recherche-commissions.component.html',
  styles: [],
})
export class RechercheCommissionsComponent {
  public readonly typesValidation = TYPES_VALIDATION;

  private readonly formBuilder = inject(NonNullableFormBuilder);

  @Input({ required: true })
  public typesCommission!: TypesCommission[];

  @Output()
  public rechercherCommissions = new EventEmitter<FiltreCommissions>();

  public formRechercheCommissions = this.formBuilder.group({
    apporteur: null as string | null,
    complet: null as string | null,
    idCommissionExterne: null as string | null,
    typeCommission: null as string | null,
    dateCommissionMin: null as Date | null,
    dateCommissionMax: null as Date | null,
  });

  public rechercher(): void {
    this.rechercherCommissions.emit(this.formRechercheCommissions.value as FiltreCommissions);
  }

  public reinitialiser(): void {
    this.formRechercheCommissions.patchValue({
      apporteur: null,
      complet: null,
      idCommissionExterne: '*',
      typeCommission: null,
      dateCommissionMin: null,
      dateCommissionMax: null,
    });
    this.rechercher();
    this.formRechercheCommissions.patchValue({
      idCommissionExterne: null,
    });
  }
}
