import { inject, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthentificationService } from './facade/authentification-facade.service';

@Injectable()
export class AuthentificationDefaultService extends AuthentificationService {
  private readonly oauthService = inject(OAuthService);

  public constructor() {
    super();
    this.isAuthenticatedSubject$.next(this.hasValidToken());
    this.oauthService.events.subscribe(() => this.isAuthenticatedSubject$.next(this.hasValidToken()));
  }

  public override async initialize(): Promise<void> {
    this.oauthService.setupAutomaticSilentRefresh();
    const hasReceivedToken = await this.oauthService.loadDiscoveryDocumentAndTryLogin();
    if (hasReceivedToken || this.hasValidToken()) {
      return;
    }
    throw 'No valid token found';
  }

  public override login(): void {
    this.oauthService.initLoginFlow();
  }
  public override logout(): void {
    this.oauthService.logOut();
  }
  public override hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }
}
