<div class="section-primary flex flex-col gap-3">
  <h2 class="title-2">Synthèse commissions</h2>
  <dl class="data-list-primary grid-cols-2 md:grid-cols-4">
    <dt>Nombre de commissions du bordereau</dt>
    <dd>{{ bordereau.nbCommission }}</dd>
    <dt>Taux de TVA</dt>
    <dd>{{ bordereau.tauxTVA != 0 ? (bordereau.tauxTVA | percent) : 'Exonération TVA' }}</dd>
    <dt>Montant HT {{ statutMontant }}</dt>
    <dd>{{ bordereau.montantHT | currency: 'EUR' }}</dd>
    <dt>Montant TVA {{ statutMontant }}</dt>
    <dd>{{ bordereau.montantTVA | currency: 'EUR' }}</dd>
    <dt>Montant TTC {{ statutMontant }}</dt>
    <dd>{{ bordereau.montantTTC | currency: 'EUR' }}</dd>
  </dl>
</div>
