import { EnvironmentProviders, Provider } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { OAuthService } from 'angular-oauth2-oidc';
import { PartenaireApiService } from '../api/commissionnement/services/partenaire-api.service';
import { AuthentificationDefaultService } from '../shared/services/authentification.service';
import { AuthentificationService } from '../shared/services/facade/authentification-facade.service';
import { PartenaireService } from '../shared/services/facade/partenaire-facade.service';
import { PaginatorIntlService } from '../shared/services/paginator-intl.service';

export const SERVICE_CONFIG: (EnvironmentProviders | Provider)[] = [
  {
    provide: PartenaireService,
    useClass: PartenaireApiService,
  },
  {
    provide: MatPaginatorIntl,
    useClass: PaginatorIntlService,
  },
  {
    provide: AuthentificationService,
    useClass: AuthentificationDefaultService,
  },
  {
    provide: OAuthService,
  },
];
