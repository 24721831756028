<div class="w-full flex justify-between items-center px-4 md:px-14 lg:px-32 py-3 bg-white">
  <div class="flex flex-row gap-10 items-center">
    <img alt="logo" src="assets/images/logo.png" />
    <span class="text-beige-30">Espace Partenaire</span>
  </div>
  @if (utilisateurService.currentUtilisateur$ | async; as utilisateur) {
    <div class="flex flex-row gap-5 items-center">
      <span class="text-miel"
        >{{ utilisateur.prenom }} {{ utilisateur.nom }} - {{ utilisateur.libelleReseau }} -
        {{ utilisateur.competences }}</span
      >
      <button class="btn-primary" (click)="authentificationService.logout()">DÉCONNEXION</button>
    </div>
  }
</div>
