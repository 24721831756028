<div class="flex flex-col gap-3">
  <div class="flex flex-row justify-between">
    <h2 class="title-2">Recherche des bordereaux</h2>
    <button class="button-icon-brown" (click)="display = !display">
      <span class="sr-only">Déplier la recherche</span>
      <mat-icon>{{ display ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
    </button>
  </div>
  <div *ngIf="display">
    <form [formGroup]="formRechercheBordereaux" class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
      <div class="flex flex-col gap-1">
        <label for="apporteur">Apporteur</label>
        <input id="apporteur" class="input-primary" formControlName="apporteur" placeholder="Saisir" />
      </div>
      <div class="flex flex-col gap-1">
        <label for="idBordereauExterne">Identifiant Bordereau Externe</label>
        <input
          id="idBordereauExterne"
          class="input-primary"
          formControlName="idBordereauExterne"
          placeholder="Saisir"
        />
      </div>
      <div class="flex flex-col gap-1">
        <span>Date de création du bordereau</span>
        <div class="flex flex-wrap gap-2 items-center">
          <div class="flex flex-row gap-2 items-center">
            <label for="dateCreationStart">Du</label>
            <div class="date-picker">
              <input
                id="dateCreationStart"
                matInput
                [matDatepicker]="pickerCreationStart"
                formControlName="dateCreationStart"
                placeholder="JJ/MM/AAAA"
              />
              <mat-datepicker-toggle matIconSuffix [for]="pickerCreationStart">
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #pickerCreationStart></mat-datepicker>
            </div>
          </div>
          <div class="flex flex-row gap-2 items-center">
            <label for="dateCreationEnd">au</label>
            <div class="date-picker">
              <input
                id="dateCreationEnd"
                matInput
                [matDatepicker]="pickerCreationEnd"
                formControlName="dateCreationEnd"
                placeholder="JJ/MM/AAAA"
              />
              <mat-datepicker-toggle matIconSuffix [for]="pickerCreationEnd">
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #pickerCreationEnd></mat-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <span>Date de paiement du bordereau</span>
        <div class="flex flex-wrap gap-2 items-center">
          <div class="flex flex-row gap-2 items-center">
            <label for="datePaiementStart">Du</label>
            <div class="date-picker">
              <input
                id="datePaiementStart"
                matInput
                [matDatepicker]="pickerPaiementStart"
                formControlName="datePaiementStart"
                placeholder="JJ/MM/AAAA"
              />
              <mat-datepicker-toggle matIconSuffix [for]="pickerPaiementStart">
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #pickerPaiementStart></mat-datepicker>
            </div>
          </div>
          <div class="flex flex-row gap-2 items-center">
            <label for="datePaiementEnd">au</label>
            <div class="date-picker">
              <input
                id="datePaiementEnd"
                matInput
                [matDatepicker]="pickerPaiementEnd"
                formControlName="datePaiementEnd"
                placeholder="JJ/MM/AAAA"
              />
              <mat-datepicker-toggle matIconSuffix [for]="pickerPaiementEnd">
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #pickerPaiementEnd></mat-datepicker>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="flex flex-row justify-end gap-3 mt-4">
      <button id="reinitialiser" class="btn-primary" (click)="reinitialiser()">RÉINITIALISER</button>
      <button id="rechercher" class="btn-primary" (click)="rechercher()">RECHERCHER</button>
    </div>
  </div>
</div>
