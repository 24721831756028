<div class="flex flex-col gap-5">
  <h2 class="title-2">Recherche de commissions</h2>
  <form [formGroup]="formRechercheCommissions" class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
    <div class="flex flex-col gap-1">
      <label for="apporteur">Apporteur</label>
      <input id="apporteur" formControlName="apporteur" class="input-primary" placeholder="Saisir" />
    </div>
    <div class="flex flex-col gap-1">
      <label for="typeCommission">Libellé de commission</label>
      <select
        id="typeCommission"
        class="select-primary"
        formControlName="typeCommission"
        [ngClass]="{ 'text-beige-30': formRechercheCommissions.value.typeCommission == null }"
      >
        <option [ngValue]="null">Sélectionner</option>
        @for (typeCommission of typesCommission; track typeCommission) {
          <option [value]="typeCommission.idRegleCommissionnement">{{ typeCommission.libelle }}</option>
        }
      </select>
    </div>
    <div class="flex flex-col gap-1">
      <label for="complet">Numéro complet</label>
      <input id="complet" formControlName="complet" class="input-primary" placeholder="Saisir" />
    </div>
    <div class="flex flex-col gap-1">
      <span>Date des commissions</span>
      <div class="flex flex-wrap gap-2 items-center">
        <div class="flex flex-row gap-2 items-center">
          <label for="dateCommissionMin">Du</label>
          <div class="date-picker">
            <input
              id="dateCommissionMin"
              matInput
              [matDatepicker]="pickerDateStart"
              formControlName="dateCommissionMin"
              placeholder="JJ/MM/AAAA"
            />
            <mat-datepicker-toggle matIconSuffix [for]="pickerDateStart">
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerDateStart></mat-datepicker>
          </div>
        </div>
        <div class="flex flex-row gap-2 items-center">
          <label for="dateCommissionMax">au</label>
          <div class="date-picker">
            <input
              id="dateCommissionMax"
              matInput
              [matDatepicker]="pickerDateEnd"
              formControlName="dateCommissionMax"
              placeholder="JJ/MM/AAAA"
            />
            <mat-datepicker-toggle matIconSuffix [for]="pickerDateEnd">
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerDateEnd></mat-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-1 md:col-span-2">
      <label for="idCommissionExterne">Identifiant de commission externe</label>
      <input
        id="idCommissionExterne"
        formControlName="idCommissionExterne"
        class="input-primary w-full"
        placeholder="Sélectionner"
      />
    </div>
  </form>
  <div class="flex flex-row justify-end gap-3">
    <button id="reinitialiser" class="btn-primary" (click)="reinitialiser()">RÉINITIALISER</button>
    <button id="rechercher" class="btn-primary" (click)="rechercher()">RECHERCHER</button>
  </div>
</div>
