export const environment = {
  api: {
    partenaire: '/api/espace-partenaire/v1',
    url: 'https://pre-commissionnement-agw.carac.fr',
  },
  msalConfig: {
    auth: {
      authority: `https://login.microsoftonline.com/1cf2db94-14b5-495b-a2a8-3b90955b1d6f/v2.0`,
      clientId: '1381ac36-87cd-487a-9ebe-fb05a276d06e',
      redirectUri: 'https://pre-espace-partenaire.carac.fr',
      scopes: 'openid profile email api://80aa56b6-0f83-4cc3-96e9-0182b3f88528/access_as_user',
    },
  },
  name: 'pre',
};
