import { Routes } from '@angular/router';
import { ConsultationBordereauxComponent } from './modules/gestion-bordereaux/pages/consultation-bordereaux/consultation-bordereaux.component';
import { BordereauDetailComponent } from './modules/gestion-bordereaux/pages/detail-bordereau/detail-bordereau.component';
import { userProfilGuard } from './shared/guards/user-profile.guard';
import { ErrorComponent } from './shared/pages/error/error.component';

export const routes: Routes = [
  {
    path: 'bordereaux',
    component: ConsultationBordereauxComponent,
    canActivate: [userProfilGuard],
  },
  {
    path: 'bordereaux/:idBordereau',
    component: BordereauDetailComponent,
    canActivate: [userProfilGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '**',
    redirectTo: 'bordereaux',
  },
];
