import { BehaviorSubject } from 'rxjs';

export abstract class AuthentificationService {
  protected isAuthenticatedSubject$ = new BehaviorSubject(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  public abstract initialize(): Promise<void>;

  public abstract login(): void;

  public abstract logout(): void;

  public abstract hasValidToken(): boolean;
}
