import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

@Injectable()
export class PaginatorIntlService implements MatPaginatorIntl {
  public changes = new Subject<void>();

  public itemsPerPageLabel = $localize`Éléments par page`;
  public nextPageLabel = $localize`Page suivante`;
  public previousPageLabel = $localize`Page précédente`;
  public firstPageLabel = $localize`Première page`;
  public lastPageLabel = $localize`Dernière page`;

  public getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize``;
    }
    const startElement = page * pageSize;
    const endElement = length < pageSize ? length : (page + 1) * pageSize;
    return $localize`${startElement} - ${endElement} sur ${length}`;
  }
}
