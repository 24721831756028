<div class="flex flex-col gap-3 section-primary">
  <h2 class="title-2">Informations du bordereau</h2>
  <dl class="data-list-primary">
    <dt>Identifiant Bordereau Externe</dt>
    <dd>{{ bordereau.idBordereauExterne }}</dd>
    <dt>Apporteur</dt>
    <dd>{{ bordereau.idApporteur ? bordereau.idApporteur : 'Multi-apporteur' }}</dd>
    <dt>Statut</dt>
    <dd>{{ bordereau.statut.toString() }}</dd>
    <dt>Date de création</dt>
    <dd>{{ bordereau.horodateCreation | date: formatDate }}</dd>
    <dt>Date de paiement</dt>
    <dd>{{ (bordereau.datePaiement | date: formatDate) ?? '-' }}</dd>
  </dl>
  <button class="btn-primary w-fit mt-3" id="telechargerBordereau" (click)="appelTelechargerBordereau()">
    TÉLÉCHARGER LE BORDEREAU
  </button>
</div>
