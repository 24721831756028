<div class="table-primary overflow-x-auto">
  <table mat-table [dataSource]="bordereaux">
    <ng-container matColumnDef="informations">
      <th mat-header-cell *matHeaderCellDef>Informations</th>
      <td mat-cell *matCellDef="let bordereau">
        <p class="flex flex-col gap-1">
          <span
            ><b>{{ bordereau.idBordereauExterne }}</b></span
          >
          <span>{{ bordereau.idApporteur ?? 'Multi-Apporteur' }}</span>
        </p>
      </td>
    </ng-container>
    <ng-container matColumnDef="dateCreation">
      <th mat-header-cell *matHeaderCellDef>Date de création</th>
      <td mat-cell *matCellDef="let bordereau">{{ bordereau.horodateCreation | date: format }}</td>
    </ng-container>
    <ng-container matColumnDef="datePaiement">
      <th mat-header-cell *matHeaderCellDef>Date de paiement</th>
      <td mat-cell *matCellDef="let bordereau">{{ (bordereau.datePaiement | date: format) ?? '-' }}</td>
    </ng-container>
    <ng-container matColumnDef="tauxTVA">
      <th mat-header-cell *matHeaderCellDef>Taux de TVA</th>
      <td mat-cell *matCellDef="let bordereau">
        {{ bordereau.tauxTVA != 0 ? (bordereau.tauxTVA | percent: '1.0-2') : 'Exonération de TVA' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="montantHT">
      <th mat-header-cell *matHeaderCellDef>Montant HT validé / payé</th>
      <td mat-cell *matCellDef="let bordereau">{{ (bordereau.montantHT | currency: 'EUR') ?? '-' }}</td>
    </ng-container>
    <ng-container matColumnDef="montantTVA">
      <th mat-header-cell *matHeaderCellDef>Montant TVA validé / payé</th>
      <td mat-cell *matCellDef="let bordereau">{{ (bordereau.montantTVA | currency: 'EUR') ?? '-' }}</td>
    </ng-container>
    <ng-container matColumnDef="montantTTC">
      <th mat-header-cell *matHeaderCellDef>Montant TTC validé / payé</th>
      <td mat-cell *matCellDef="let bordereau">{{ (bordereau.montantTTC | currency: 'EUR') ?? '-' }}</td>
    </ng-container>
    <ng-container matColumnDef="statut">
      <th mat-header-cell *matHeaderCellDef>Statut</th>
      <td mat-cell *matCellDef="let bordereau">
        {{ bordereau.statut.toString() === 'PAYE' ? bordereau.statut.toString() : 'ATTENTE PAIEMENT' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        <div class="text-center w-full">Actions</div>
      </th>
      <td mat-cell *matCellDef="let bordereau">
        <div class="flex flex-row gap-3 justify-center">
          <a
            [routerLink]="[bordereau.idBordereau]"
            [attr.id]="'consulter' + bordereau.idBordereau"
            class="btn-primary-outline px-3 py-1"
          >
            CONSULTER
          </a>
          <button
            [attr.id]="'telecharger' + bordereau.idBordereau"
            (click)="appelTelechargerBordereau(bordereau.idBordereau)"
            class="btn-primary-outline px-3 py-1"
          >
            TÉLÉCHARGER
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
