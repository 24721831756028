import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FiltreBordereaux } from '../../../../shared/interfaces/filtre-bordereaux.interface';

@Component({
  selector: 'espace-partenaire-recherche-bordereaux',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, CommonModule, ReactiveFormsModule, MatIconModule],
  templateUrl: './recherche-bordereaux.component.html',
  styles: [],
})
export class RechercheBordereauxComponent {
  protected display: boolean = true;

  private readonly formBuilder = inject(NonNullableFormBuilder);

  @Output()
  public rechercherBordereaux = new EventEmitter<FiltreBordereaux>();

  public formRechercheBordereaux = this.formBuilder.group({
    idBordereauExterne: null as string | null,
    apporteur: null as string | null,
    dateCreationStart: null as Date | null,
    dateCreationEnd: null as Date | null,
    datePaiementStart: null as Date | null,
    datePaiementEnd: null as Date | null,
  });

  public rechercher(): void {
    this.rechercherBordereaux.emit(this.formRechercheBordereaux.value as FiltreBordereaux);
  }

  public reinitialiser(): void {
    this.formRechercheBordereaux.patchValue({
      apporteur: null,
      dateCreationStart: null,
      dateCreationEnd: null,
      datePaiementEnd: null,
      datePaiementStart: null,
    });
    this.rechercher();
  }
}
