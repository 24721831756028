import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BordereauDetaillePartenaire } from '../../../../api/commissionnement/model/bordereau-detaille-partenaire';

@Component({
  selector: 'espace-partenaire-synthese-commissions',
  standalone: true,
  imports: [CommonModule, CurrencyPipe],
  templateUrl: './synthese-commissions.component.html',
  styles: [],
})
export class SyntheseCommissionsComponent {
  @Input({ required: true })
  public bordereau!: BordereauDetaillePartenaire;
}
