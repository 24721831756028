import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Commission } from '../../../../api/commissionnement/model/commission';

@Component({
  selector: 'espace-partenaire-tableau-commission',
  standalone: true,
  imports: [MatTableModule, CurrencyPipe, CommonModule],
  templateUrl: './tableau-commission.component.html',
})
export class TableauCommissionComponent {
  public readonly displayedColumns: string[] = ['commission', 'modeRemuneration', 'montant'];

  @Input({ required: true })
  public commissions: Commission[] = [];

  public dataSource = new MatTableDataSource<Commission>();

  protected ngOnChanges(): void {
    if (this.commissions) {
      this.dataSource.data = this.commissions;
    }
  }
}
