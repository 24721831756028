import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';
import { BehaviorSubject, Observable, catchError, combineLatest, map, mergeMap, of } from 'rxjs';
import { Bordereau } from '../../../../api/commissionnement/model/bordereau';
import { FiltreBordereaux } from '../../../../shared/interfaces/filtre-bordereaux.interface';
import { PartenaireService } from '../../../../shared/services/facade/partenaire-facade.service';
import { RechercheBordereauxComponent } from '../../components/recherche-bordereaux/recherche-bordereaux.component';
import { TableauBordereauComponent } from '../../components/tableau-bordereau/tableau-bordereau.component';

@Component({
  selector: 'espace-partenaire-consultation-bordereaux',
  standalone: true,
  imports: [CommonModule, RechercheBordereauxComponent, TableauBordereauComponent, MatPaginator, RouterLink],
  templateUrl: './consultation-bordereaux.component.html',
})
export class ConsultationBordereauxComponent {
  public readonly PAGE_SIZE = 10;
  private readonly partenaireService = inject(PartenaireService);
  private readonly page = new BehaviorSubject(0);
  private readonly filtreBordereaux$ = new BehaviorSubject({} as FiltreBordereaux);
  public readonly paginateBordereaux$: Observable<{ bordereaux: Bordereau[]; length: number }>;

  public constructor() {
    const bordereaux$ = this.filtreBordereaux$.pipe(
      mergeMap((filtre) => {
        const newFiltre = { ...filtre };
        return this.partenaireService.getBordereaux(newFiltre).pipe(catchError(() => of([])));
      })
    );
    this.paginateBordereaux$ = combineLatest([bordereaux$, this.page]).pipe(
      map(([bordereaux, page]) => ({
        bordereaux: bordereaux.slice(page * this.PAGE_SIZE, (page + 1) * this.PAGE_SIZE),
        length: bordereaux.length,
      }))
    );
  }

  public rechercherBordereaux(filtre: FiltreBordereaux): void {
    this.filtreBordereaux$.next(filtre);
  }

  public setPage(page: number): void {
    this.page.next(page);
  }
}
