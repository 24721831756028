import { HttpInterceptorFn } from '@angular/common/http';
import { authInterceptor } from '../shared/interceptors/auth.interceptor';

export const INTERCEPTORS_CONFIG: { [conf: string]: HttpInterceptorFn[] } = {
  local: [authInterceptor],
  dev: [authInterceptor],
  rec: [authInterceptor],
  pre: [authInterceptor],
  prod: [authInterceptor],
  pro: [authInterceptor]
};
