<div class="table-primary overflow-x-auto">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <!-- Commission Column -->
    <ng-container matColumnDef="commission">
      <th mat-header-cell *matHeaderCellDef>Informations Commission</th>
      <td mat-cell *matCellDef="let commission">
        <div class="grid grid-cols-1 gap-2 mt-2 mb-2">
          <span class="font-bold text-brown-600 col-span-2">{{ commission.libelle }}</span>
          <span class="col-span-2"
            >{{ commission.idCommissionExterne }}
            @if (commission.typeCommission !== 'EXCEPTIONNELLE') {
              - {{ commission.numeroComplet }}
            }
          </span>
          <span class="col-span-2">
            @if (commission.typeCommission !== 'EXCEPTIONNELLE') {
              {{ commission.idApporteur ?? 'multi-apporteur' }}
            }
          </span>
        </div>
      </td>
    </ng-container>
    <!-- Mode de Rémunération Column -->
    <ng-container matColumnDef="modeRemuneration">
      <th mat-header-cell *matHeaderCellDef>Mode de Rémunération</th>
      <td mat-cell *matCellDef="let commission">
        {{ commission.typeCommission !== 'EXCEPTIONNELLE' ? commission.modeRemuneration : 'FORFAIT' }}
      </td>
    </ng-container>
    <!-- Montant HT Column -->
    <ng-container matColumnDef="montantHT">
      <th mat-header-cell *matHeaderCellDef>Montant HT validé</th>
      <td mat-cell *matCellDef="let commission">{{ commission.montantHT | currency: 'EUR' }}</td>
    </ng-container>
    <!-- Montant TVA Column -->
    <ng-container matColumnDef="montantTVA">
      <th mat-header-cell *matHeaderCellDef>Montant TVA validé</th>
      <td mat-cell *matCellDef="let commission">{{ commission.montantTVA | currency: 'EUR' }}</td>
    </ng-container>
    <!-- Montant TTC Column -->
    <ng-container matColumnDef="montantTTC">
      <th mat-header-cell *matHeaderCellDef>Montant TTC validé</th>
      <td mat-cell *matCellDef="let commission">{{ commission.montantTTC | currency: 'EUR' }}</td>
    </ng-container>
    <!-- Header and Row Definitions -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
