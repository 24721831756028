export const RESEAUX: { [key: string]: string } = {
  '1': 'Crédit Coopératif',
  '2': 'Unéo',
  '3': 'Partenaire 3',
  '4': 'Intégrance',
  '5': 'BFM',
};

export const ETABLISSEMENTS: { [key: string]: string } = {
  '001': '001',
  '004': '004',
};

export const TYPES_COMMISSION: { [key: string]: string } = {
  EVENEMENTIELLE: 'Événementielle',
  CUMULATIVE: 'Cumulative',
};

export const TYPES_VALIDATION: { [key: string]: string } = {
  SANS_VALIDATION: 'Sans Validation',
  DEROGE: 'Dérogé',
  VALIDE: 'Validé',
  REFUSE: 'Refusé',
};

export class Constants {
  public static readonly FORMAT_DATE_FR = 'dd/MM/yyyy';
  public static readonly FORMAT_DATE_ISO_8601 = 'yyyy-MM-dd';
  public static readonly LOCALE = 'fr';
}
