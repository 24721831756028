import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, map, mergeMap, of } from 'rxjs';
import { BordereauDetaillePartenaire } from '../../../../api/commissionnement/model/bordereau-detaille-partenaire';
import { Commission } from '../../../../api/commissionnement/model/commission';
import { RechercherCommissionsBordereauPartenaireResponse } from '../../../../api/commissionnement/model/rechercher-commissions-bordereau-partenaire-response';
import { TypesCommission } from '../../../../api/commissionnement/model/types-commission';
import { FiltreCommissions } from '../../../../shared/interfaces/filtre-commissions.interface';
import { PartenaireService } from '../../../../shared/services/facade/partenaire-facade.service';
import { InformationsBordereauComponent } from '../../components/informations-bordereau/informations-bordereau.component';
import { RechercheCommissionsComponent } from '../../components/recherche-commissions/recherche-commissions.component';
import { SyntheseCommissionsComponent } from '../../components/synthese-commissions/synthese-commissions.component';
import { TableauCommissionComponent } from '../../components/tableau-commission/tableau-commission.component';

const EMPTY_GUID: string = '00000000-0000-0000-0000-000000000000';

@Component({
  selector: 'espace-partenaire-bordereau-detail',
  standalone: true,
  imports: [
    RouterLink,
    InformationsBordereauComponent,
    CommonModule,
    TableauCommissionComponent,
    RechercheCommissionsComponent,
    MatPaginatorModule,
    MatIconModule,
    SyntheseCommissionsComponent,
  ],
  templateUrl: './detail-bordereau.component.html',
})
export class BordereauDetailComponent implements OnInit {
  private readonly partenaireService = inject(PartenaireService);

  public readonly PAGE_SIZE = 10;

  public bordereau: BordereauDetaillePartenaire | undefined;
  public typesCommission: TypesCommission[] | undefined;

  public readonly commissionsStandards$: Observable<RechercherCommissionsBordereauPartenaireResponse | null>;
  public readonly commissionsExceptionnelle$: Observable<RechercherCommissionsBordereauPartenaireResponse | null>;
  private readonly page$ = new BehaviorSubject(0);
  private readonly filtres$ = new BehaviorSubject(null as FiltreCommissions | null);

  @Input()
  public idBordereau!: string;

  public constructor() {
    this.commissionsStandards$ = combineLatest([this.filtres$, this.page$]).pipe(
      mergeMap(([filtres, page]) => {
        if (filtres) {
          return this.partenaireService.getCommissionByBordereau(this.idBordereau, page, this.PAGE_SIZE, filtres).pipe(
            map((response) => {
              const commissionsStandards: Commission[] =
                response.commissions.filter((c) => c.typeCommission !== 'EXCEPTIONNELLE') ?? [];

              return {
                commissions: commissionsStandards,
                pagination: {
                  ...response.pagination,
                  nombreElements: commissionsStandards.length,
                },
              };
            })
          );
        }
        return of(null);
      })
    );

    this.commissionsExceptionnelle$ = combineLatest([this.filtres$, this.page$]).pipe(
      mergeMap(([filtres, page]) => {
        if (filtres) {
          if (filtres.typeCommission === null || filtres.typeCommission === EMPTY_GUID) {
            filtres.typeCommission = EMPTY_GUID;
            const response = this.partenaireService.getCommissionByBordereau(
              this.idBordereau,
              page,
              this.PAGE_SIZE,
              filtres
            );
            filtres.typeCommission = null;
            return response;
          }
          return of(null);
        }
        return of(null);
      })
    );
  }

  public ngOnInit(): void {
    this.partenaireService.getBordereau(this.idBordereau).subscribe((bordereau) => (this.bordereau = bordereau));
    this.partenaireService
      .consulterTypeCommissions(this.idBordereau)
      .subscribe((typesCommisions) => (this.typesCommission = typesCommisions));
  }

  public setPage(page: number): void {
    this.page$.next(page);
  }

  public setFiltre(filtre: FiltreCommissions): void {
    this.filtres$.next(filtre);
  }
}
