import { Observable } from 'rxjs';
import { Bordereau } from '../../../api/commissionnement/model/bordereau';
import { BordereauDetaillePartenaire } from '../../../api/commissionnement/model/bordereau-detaille-partenaire';
import { RechercherCommissionsBordereauPartenaireResponse } from '../../../api/commissionnement/model/rechercher-commissions-bordereau-partenaire-response';
import { TelechargerBordereauCaracResponse } from '../../../api/commissionnement/model/telecharger-bordereau-carac-response';
import { TypesCommission } from '../../../api/commissionnement/model/types-commission';
import { Utilisateur } from '../../../api/commissionnement/model/utilisateur';
import { FiltreBordereaux } from '../../interfaces/filtre-bordereaux.interface';
import { FiltreCommissions } from '../../interfaces/filtre-commissions.interface';

export abstract class PartenaireService {
  public abstract getBordereau(idBordereau: string): Observable<BordereauDetaillePartenaire>;
  public abstract getBordereaux(filtres: FiltreBordereaux): Observable<Bordereau[]>;
  public abstract getCommissionByBordereau(
    idBordereau: string,
    page: number,
    pageSize: number,
    filtre: FiltreCommissions
  ): Observable<RechercherCommissionsBordereauPartenaireResponse>;
  public abstract consulterTypeCommissions(idBordereau: string): Observable<TypesCommission[]>;
  public abstract telechargerBordereau(idBordereau: string): Observable<TelechargerBordereauCaracResponse>;
  public abstract getAgent(): Observable<Utilisateur>;
}
