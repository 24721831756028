import { HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const headers = new HttpHeaders({
    idCorrelation: uuid(),
  });

  const newReq = req.clone({
    headers,
  });
  return next(newReq);
}
