import { APP_INITIALIZER } from '@angular/core';
import { AuthConfig, OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { AuthentificationService } from '../shared/services/facade/authentification-facade.service';

export function authentificationFactory(authService: AuthentificationService): () => Promise<void> {
  return () => authService.initialize();
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

export const AUTH_CONFIG = [
  provideOAuthClient({
    resourceServer: { allowedUrls: [environment.api.url], sendAccessToken: true },
  }),
  {
    provide: APP_INITIALIZER,
    useFactory: authentificationFactory,
    deps: [AuthentificationService],
    multi: true,
  },
  { provide: OAuthStorage, useFactory: storageFactory },
  {
    provide: AuthConfig,
    useValue: {
      issuer: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      clientId: environment.msalConfig.auth.clientId,
      responseType: 'code',
      scope: environment.msalConfig.auth.scopes,
      strictDiscoveryDocumentValidation: false,
    } satisfies AuthConfig,
  },
];
