import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from '../app.routes';
import { authInterceptor } from '../shared/interceptors/auth.interceptor';
import { API_CONFIG } from './api.config';
import { AUTH_CONFIG } from './auth.config';
import { SERVICE_CONFIG } from './service.config';

registerLocaleData(localeFr, 'fr');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authInterceptor]), withInterceptorsFromDi()),
    ...API_CONFIG,
    ...SERVICE_CONFIG,
    ...AUTH_CONFIG,
    provideNativeDateAdapter(),
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
};
