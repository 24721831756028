import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthentificationService } from '../../services/facade/authentification-facade.service';
import { CurrentUtilisateurService } from '../../services/utilisateur.service';

@Component({
  selector: 'espace-partenaire-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  protected readonly utilisateurService = inject(CurrentUtilisateurService);
  protected readonly authentificationService = inject(AuthentificationService);
}
