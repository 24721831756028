<table mat-table [dataSource]="bordereaux" class="table-primary">
  <ng-container matColumnDef="informations">
    <th mat-header-cell *matHeaderCellDef>Informations</th>
    <td mat-cell *matCellDef="let bordereau">
      <p class="flex flex-col gap-1">
        <span
          ><b>{{ bordereau.idBordereauExterne }}</b></span
        >
        <span>{{ bordereau.idApporteur ?? 'Multi-Apporteur' }}</span>
      </p>
    </td>
  </ng-container>
  <ng-container matColumnDef="dateCreation">
    <th mat-header-cell *matHeaderCellDef>Date de création</th>
    <td mat-cell *matCellDef="let bordereau">{{ bordereau.horodateCreation | date: format }}</td>
  </ng-container>
  <ng-container matColumnDef="datePaiement">
    <th mat-header-cell *matHeaderCellDef>Date de paiement</th>
    <td mat-cell *matCellDef="let bordereau">{{ (bordereau.datePaiement | date: format) ?? '-' }}</td>
  </ng-container>
  <ng-container matColumnDef="montant">
    <th mat-header-cell *matHeaderCellDef>Montant validé / payé</th>
    <td mat-cell *matCellDef="let bordereau">{{ bordereau.montant | currency: 'EUR' }}</td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef>Statut</th>
    <td mat-cell *matCellDef="let bordereau">
      {{ bordereau.statut.toString() === 'PAYE' ? bordereau.statut.toString() : 'ATTENTE PAIEMENT' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <div class="text-center w-full">Actions</div>
    </th>
    <td mat-cell *matCellDef="let bordereau">
      <div class="flex flex-row gap-3 justify-center">
        <a
          [routerLink]="[bordereau.idBordereau]"
          [attr.id]="'consulter' + bordereau.idBordereau"
          class="btn-primary-outline px-3 py-1"
        >
          CONSULTER
        </a>
        <button
          [attr.id]="'telecharger' + bordereau.idBordereau"
          (click)="appelTelechargerBordereau(bordereau.idBordereau)"
          class="btn-primary-outline px-3 py-1"
        >
          TÉLÉCHARGER
        </button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
