<div class="container py-5 flex flex-col gap-4 mx-auto">
  <h1 class="title">DÉTAIL D'UN BORDEREAU</h1>
  @if (bordereau) {
    <espace-partenaire-informations-bordereau [bordereau]="bordereau!" />
    <espace-partenaire-synthese-commissions [bordereau]="bordereau!" />
    <div class="section-primary flex flex-col gap-4">
      <espace-partenaire-recherche-commissions
        [typesCommission]="typesCommission ?? []"
        (rechercherCommissions)="setFiltre($event)"
      />

      @if (commissionsStandards$ | async; as commission) {
        <span [ngPlural]="commission.pagination.nombreElements ?? 0">
          <ng-template ngPluralCase="0">Aucune commission standard ne correpond à votre recherche</ng-template>
          <ng-template ngPluralCase="1">1 commission standard correpond à votre recherche</ng-template>
          <ng-template ngPluralCase="other"
            >{{ commission.pagination.nombreElements }} commissions standards correspondent à votre
            recherche</ng-template
          >
        </span>
        @if (commission.pagination.nombreElements !== 0) {
          <espace-partenaire-tableau-commission [commissions]="commission.commissions" />
          <mat-paginator
            class="paginator"
            (page)="setPage($event.pageIndex)"
            [hidePageSize]="true"
            [length]="commission.pagination.nombreElements"
            [pageSize]="PAGE_SIZE"
            [showFirstLastButtons]="true"
          />
        }
      }
      @if (commissionsExceptionnelle$ | async; as commission) {
        <span [ngPlural]="commission.pagination.nombreElements ?? 0">
          <ng-template ngPluralCase="0">Aucune commission exceptionnelle ne correpond à votre recherche</ng-template>
          <ng-template ngPluralCase="1">1 commission exceptionnelle correpond à votre recherche</ng-template>
          <ng-template ngPluralCase="other"
            >{{ commission.pagination.nombreElements }} commissions exceptionnelles correspondent à votre
            recherche</ng-template
          >
        </span>
        @if (commission.pagination.nombreElements !== 0) {
          <espace-partenaire-tableau-commission [commissions]="commission.commissions" />
          <mat-paginator
            class="paginator"
            (page)="setPage($event.pageIndex)"
            [hidePageSize]="true"
            [length]="commission.pagination.nombreElements"
            [pageSize]="PAGE_SIZE"
            [showFirstLastButtons]="true"
          />
        }
      }
    </div>
  } @else {
    <span>Une erreur est survenue durant la récupération du bordereau</span>
  }

  <div class="flex flex-row justify-between">
    <a [routerLink]="['../']" class="btn-primary-outline flex flex-row items-center gap-1" id="retourDetail">
      <mat-icon fontIcon="arrow_back" class="scale-90" />
      RETOUR
    </a>
  </div>
</div>
