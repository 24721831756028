import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthentificationService } from '../services/facade/authentification-facade.service';
import { CurrentUtilisateurService } from '../services/utilisateur.service';

export const userProfilGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const utilisateurService = inject(CurrentUtilisateurService);
  const authentificationService = inject(AuthentificationService);
  const router = inject(Router);

  return utilisateurService.utilisateur$.pipe(
    map((utilisateur) => {
      if (utilisateur === undefined) {
        return router.parseUrl('/error');
      }
      if (utilisateur === false) {
        authentificationService.login();
        return false;
      }
      return true;
    })
  );
};
