import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BordereauDetaillePartenaire } from '../../../../api/commissionnement/model/bordereau-detaille-partenaire';

@Component({
  selector: 'espace-partenaire-synthese-commissions',
  standalone: true,
  imports: [CommonModule, CurrencyPipe],
  templateUrl: './synthese-commissions.component.html',
  styles: [],
})
export class SyntheseCommissionsComponent {
  @Input({ required: true })
  public bordereau!: BordereauDetaillePartenaire;

  protected get statutMontant(): string {
    let result: string = '';

    if (this.bordereau.statut === 'ENVOYE') {
      result = 'Validé';
    } else if (this.bordereau.statut === 'PAYE') {
      result = 'Payé';
    }

    return result;
  }
}
