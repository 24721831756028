<div class="table-primary">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <!-- Commission Column -->
    <ng-container matColumnDef="commission">
      <th mat-header-cell *matHeaderCellDef>Informations Commission</th>
      <td mat-cell *matCellDef="let commission">
        <div class="grid grid-cols-1 gap-2 mt-2 mb-2">
          <span class="font-bold text-brown-600 col-span-2">{{ commission.libelle }}</span>
          <span class="col-span-2">{{ commission.idCommissionExterne }} - {{ commission.numeroComplet }}</span>
          <span class="col-span-2">{{ commission.idApporteur }}</span>
        </div>
      </td>
    </ng-container>
    <!-- Mode de Rémunération Column -->
    <ng-container matColumnDef="modeRemuneration">
      <th mat-header-cell *matHeaderCellDef>Mode de Rémunération</th>
      <td mat-cell *matCellDef="let commission">{{ commission.modeRemuneration }}</td>
    </ng-container>
    <!-- Montant Column -->
    <ng-container matColumnDef="montant">
      <th mat-header-cell *matHeaderCellDef>Montant validé</th>
      <td mat-cell *matCellDef="let commission">{{ commission.montant | currency: 'EUR' }}</td>
    </ng-container>
    <!-- Header and Row Definitions -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
